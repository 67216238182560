<template >
  <div class="whatsapp-c">
    <a
      href="https://wa.link/axc3af"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src="../../assets/wasa.svg" alt="wapp" />
    </a>
  </div>
</template>
<script>
export default {};
</script>
<style >
.whatsapp-c {
  position: fixed;
  right: 5.072vw;
  bottom: 6.763vw;
  z-index: 1100;
}
.whatsapp-c img {
  width: 14.010vw;
  height: 14.010vw;
}
@media (min-width: 768px) {
.whatsapp-c { 
  right: 1.146vw;
  bottom: 1.302vw; 
}
  .whatsapp-c img {
    width: 1.979vw;
    height: 1.979vw;
  }
}
</style>