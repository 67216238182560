import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import category from './modules/category'
import clientes from './modules/clientes'
import logo from './modules/logo'
import main from './modules/main'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import servicios from './modules/servicios'
import banner from './modules/banner'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    main: main,
    usuarios: usuarios,
    profile:profile,
    colors:colors,
    logo:logo,
    category:category,
    clientes:clientes,
    servicios: servicios,
    banner:banner,
  }
})
