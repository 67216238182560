<template>
  <div id="app">
    <WApp v-if="$route.name !== 'proximamente'" />
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import WApp from "./components/fixed/WApp.vue";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },
  components: { WApp },
  async created() {
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>



<style>
@import "./css/default.css";

@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;600;700&family=Kanit:wght@400;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #e21f1d;
  --color-2: #242424;
  --color-3: #a7a7a7;
  --color-4: #f5f5f5;
  --color-5: #ffffff;
  --color-6: #d9d9d9;
  --color-7: #ffffff;

  --color-8: #5cbb5b;
  --color-9: #272d69;
  --color-10: #5c5c5c;

  --colorp-1: rgba(39, 45, 105, 1);
  --colorp-2: rgba(92, 92, 92, 1);
  --colorp-3: rgba(92, 187, 91, 1);
  --colorp-4: #f5f5f5;
  --colorp-5: #ffffff;
  --colorp-6: #d9d9d9;
  --colorp-7: rgba(255, 92, 0, 1);

  --Cabin: "Cabin", sans-serif;
  --Kanit: "Kanit", sans-serif;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

body {
  margin: 0vw;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}
.sliderbanner .VueCarousel-pagination {
  margin-top: 25vw;
  z-index: 1;
  position: absolute;
}

.sliderbanner .VueCarousel-dot-container {
  margin-top: 0vw !important;
}
.sliderbanner .VueCarousel-dot-container {
  margin-top: 0vw !important;
}

/* Esto se puede quitar en caso de existir mas desplazamientos por puntos en slide de carousel */
.VueCarousel-dot {
  padding: 0.3125vw !important;
}
.VueCarousel-dot--active {
  background: #5c5c5c !important;
}

input#select-ch,input#select-ch2,input#select-ch3 ,input#select-ch4 ,input#select-ch5 ,input#select-ch6 {
  display: none;
}

#servicios .slide-it .VueCarousel-navigation-button {
  width: 2.396vw;
  height: 2.396vw;
  background-image: url("assets/btnsl.svg");
  background-size: 2.396vw;
  font-size: 0;
  padding: 0 !important;
}
#servicios .slide-it .VueCarousel-navigation-prev {
    left: 6vw;
    margin: 0!important;
    transform: translateY(0%) translateX(0%);
}
#servicios .slide-it .VueCarousel-navigation-next {
    right: 6vw;
    transform:  translateY(0%) translateX(0%) rotate(180deg);
    margin: 0!important; 
}
#servicios .VueCarousel-dot {
    width: 0.833vw!important;
    height: 0.833vw!important;background-color: #bdbdbd!important;
}
#servicios .VueCarousel-dot.VueCarousel-dot--active {
    width: 0.833vw!important;
    height: 0.833vw!important;
    padding: 0!important;
    background: linear-gradient(141.9deg, #85E384 0.23%, #1B9C51 94.51%)!important;
}
@media (min-width: 768px) {
  .sliderbanner .VueCarousel-dot {
    width: 0.833vw !important;
    height: 0.833vw !important;
    padding: 0 !important;
    padding: 0 !important;
    margin-right: 0.625vw;
  }
  .sliderbanner .VueCarousel-dot--active {
    background: linear-gradient(
      141.9deg,
      #85e384 0.23%,
      #1b9c51 94.51%
    ) !important;
  }
}
@media (max-width: 768px) {
  #servicios .slide-it .VueCarousel-navigation-button {
    width: 17.150vw;
    height: 17.150vw;
    background-size: 17.150vw;
    top: 106vw;
}
  #servicios .slide-it .VueCarousel-dot-container{
  display: none;
}
#servicios .slide-it .VueCarousel-navigation-prev {
    left: 15vw; 
}
#servicios .slide-it .VueCarousel-navigation-next {
    right: 15vw; 
}
#servicios .slide-it .VueCarousel-slide {
    display: flex;
    align-items: center;
}
  p.NButton {
    margin-left: -43vw;
    margin-top: 77vw;
    display: flex;
    color: #ffffff;
    font-size: 11vw;
    font-weight: 300;
    transform: rotateY(-160deg);
    display: flex;
    transform-origin: left;
  }

  .sliderbanner .VueCarousel-pagination {
    display: none;
  }
  .VueCarousel-dot {
    margin-right: 1.85vw;
    width: 3.865vw !important;
    height: 3.865vw !important;
  }
}

.dflx {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}
</style>
