"use strict";

const cPost = '6eV_bfJ/ths-zGM'
const cGet = 'F3f_LJd/yKd-Op9'
const cPut = '7GY_LUA/ERm-2VJ'
const cDel = 'U15_1kB/q0w-jo0'

exports.endpointCodes  = {
      post: cPost,
      get: cGet,
      put: cPut,
      del: cDel
}


const local ='http://localhost:3424'
const cpanel ='https://alterenergy.com.mx' // cambiar por la url del sitio web.

exports.url  =  cpanel + '/api/yh4_eFV/u7R-7Kd'; 

